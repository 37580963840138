/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { computed, inject } from '@angular/core';
import { getState, patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { isEmpty } from 'lodash-es';

import { AUTH_CHALLENGE_NAME, AUTH_STATE_INJECTION_TOKEN, AuthProvider, AuthState, BaseUserData, IntegrationAuthData, UserRole } from '../models/auth-store.models';

export const AuthStore = signalStore(
	{ providedIn: 'root' },
	withState(() => inject(AUTH_STATE_INJECTION_TOKEN)),
	withComputed((store) => ({
		isAuthenticated: computed(() => store.loaded() && !store.challengeName() && !isEmpty(store.user())),
		isSSOUser: computed(() => store.provider() === AuthProvider.Okta),
		isSealightsAdminUser: computed(() => store.user()?.role === UserRole.SealightsAdmin),
		isDevopsUser: computed(() => store.user()?.role === UserRole.UserDevops),
		getUserRole: computed(() => store.user()?.role),
		fullName: computed(() => constructFullName(store.user())),
		avatarInfo: computed(() => constructAvatarInfo(store.user())),
		getCustomerId: computed(() => store.user()?.customerId),
		getUserEmail: computed(() => store.user()?.email),
		isTokenExpired: computed(() => constructIsTokenExpired()), // Not sure this should be here
	})),
	withComputed((store) => ({
		getAccessToken: computed(() => store.isSSOUser() ? store.accessToken() : store.token()), // To use previous computed values, it has to come in separate withComputed
	})),
	withMethods((store, resetState = inject(AUTH_STATE_INJECTION_TOKEN)) => ({
		reset(): void {
			patchState(store, () => resetState);
			removeUserFullDataFromLocalStorage();
		},
		save(data: Partial<AuthState>): void {
			patchState(store, { ...data });
			saveDataInLocalStorage(getState(store));
		},
		restore(): boolean {
			try {
				const data = restoreAuthFullData();
				patchState(store, { ...data });
				return true;
			} catch(error) {
				console.error(error);
				return false;
			}
		}
	}))
);

const constructFullName = (authUser: BaseUserData): string => {
	const noUser = { name: '', email: '' } as BaseUserData;
	const user = authUser ?? noUser;
	let fullName = user.name ?? '';

	if (fullName) {
		if (user.family_name) {
			fullName += ` ${user.family_name}`;
		}
	} else {
		fullName = user.email;
	}

	return fullName;
};

const constructIsTokenExpired = (): boolean => {
	const currentTime = new Date().getTime();

	if (localStorage.getItem('auth-token-expiry') === null) {
		return true;
	}

	const expiryTime = new Date(localStorage.getItem('auth-token-expiry')).getTime();

	return currentTime > expiryTime;
};

const constructAvatarInfo = (user: BaseUserData): { fullName: string; email: string } => {
	const userObj = {} as { fullName: string; email: string };

	if (user) {
		if (user.name || user.family_name) {
			userObj.fullName = `${user.name || ''} ${user.family_name || ''}`;
		} else {
			userObj.fullName = user.email;
		}
	} else {
		userObj.fullName = 'Unidentified';
	}

	return userObj;
};

const saveDataInLocalStorage = (userFullData: AuthState): void => {
	localStorage.setItem('userData', JSON.stringify(userFullData.user));

	if (userFullData.originalUser) {
		localStorage.setItem('originalUserData', JSON.stringify(userFullData.originalUser));
	}

	if (userFullData.integrations) {
		localStorage.setItem('integrations', JSON.stringify(userFullData.integrations));
	}

	localStorage.setItem('provider', userFullData.provider);

	if (userFullData.challengeName === AUTH_CHALLENGE_NAME) {
		localStorage.setItem('challengeName', userFullData.challengeName);
		localStorage.setItem('session', userFullData.session);
	} else if (!userFullData.provider || userFullData.provider === AuthProvider.SeaLights) {
		localStorage.setItem('auth-token-expiry', userFullData.expiresTimestamp);
		localStorage.setItem('auth-token', userFullData.token);
	} else {
		localStorage.setItem('auth-token-expiry', userFullData.expiresTimestamp);
		localStorage.setItem('access-token', userFullData.accessToken);
		localStorage.setItem('refresh-token', userFullData.refreshToken);
		localStorage.setItem('auth-token', userFullData.token);
		localStorage.setItem('jwt', userFullData.jwt);
	}
};

const removeUserFullDataFromLocalStorage = (): void => {
	localStorage.removeItem('auth-token-expiry');
	localStorage.removeItem('auth-token');
	localStorage.removeItem('refresh-token');
	localStorage.removeItem('access-token');
	localStorage.removeItem('jwt');
	localStorage.removeItem('userData');
	localStorage.removeItem('originalUserData');
	localStorage.removeItem('challengeName');
	localStorage.removeItem('session');
	localStorage.removeItem('provider');
	localStorage.removeItem('integrations');
	localStorage.removeItem('okta-sso-code');
};

const restoreAuthFullData = (): AuthState => {
	const authFullData: AuthState = {
		loaded: true,
		provider: localStorage.getItem('provider') as AuthProvider,
		session: localStorage.getItem('session'),
		challengeName: localStorage.getItem('challengeName'),
		expiresTimestamp: localStorage.getItem('auth-token-expiry'),
		token: localStorage.getItem('auth-token'),
		accessToken: localStorage.getItem('access-token'),
		refreshToken: localStorage.getItem('refresh-token'),
		jwt: localStorage.getItem('jwt'),
		user: JSON.parse(localStorage.getItem('userData')) as BaseUserData,
		originalUser: JSON.parse(localStorage.getItem('originalUserData')) as BaseUserData,
		integrations: JSON.parse(localStorage.getItem('integrations')) as IntegrationAuthData,
	};

	if (authFullData.user && !authFullData.integrations) {
		throw new Error('No integrations found');
	}

	return authFullData;

	// check token validity
};

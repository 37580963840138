import { InjectionToken } from '@angular/core';

import { LDContext } from '@@core/models/auth/auth-model';

export enum AuthProvider {
	Okta = 'okta',
	Cognito = 'cognito',
	SeaLights = 'sealights'
}

export interface BaseUserData {
	email: string;
	customerId?: string;
	role?: UserRole;
	name?: string;
	family_name?: string;
	userName?: string;
}

export enum UserRole {
	SealightsAdmin = 'sealights-admin',
	UserAdmin = 'user-admin',
	UserDevops = 'user-devops',
	User = 'user',
	UserLimited = 'user-limited'
}

export interface IntegrationAuthData {
	ld?: {
		context: LDContext;
		hash: string;
	};
}

export interface AuthState {
	user?: BaseUserData;
	loaded: boolean;
	token?: string;
	challengeName?: string;
	session?: string;
	jwt?: string;
	refreshToken?: string;
	accessToken?: string;
	authToken?: string;
	expires?: number | string;
	expiresTimestamp: string;
	provider?: AuthProvider;
	originalUser?: BaseUserData;
	integrations: IntegrationAuthData;
}

export const initialAuthState: AuthState = {
	expiresTimestamp: null,
	integrations: null,
	loaded: false,
	accessToken: null,
	authToken: null,
	challengeName: null,
	expires: null,
	jwt: null,
	originalUser: null,
	provider: null,
	refreshToken: null,
	session: null,
	token: null,
	user: null
};

export const AUTH_STATE_INJECTION_TOKEN = new InjectionToken<AuthState>('AUTH_STATE', {
	factory: (): AuthState => initialAuthState,
});

export const AUTH_CHALLENGE_NAME = 'NEW_PASSWORD_REQUIRED';
